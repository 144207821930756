<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="userForm"
    label-width="120px"
    class="company-ruleForm"
    status-icon
    v-loading="loading"
    :disabled="submitting"
  >
    <el-form-item label="用户名" prop="name" class="base-form-item">
      <el-input
        v-model.trim="form.name"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <el-form-item label="登录账户名" prop="username" class="base-form-item">
      <el-input
        v-model.trim="form.username"
        :disabled="!isEmprty(this.currentId)"
        clearable
        placeholder="请输入"
      ></el-input>
    </el-form-item>

    <el-form-item label="用户角色" prop="roles" class="role-form-item">
      <RolesForm v-model="form.roleIds" />
    </el-form-item>

    <el-form-item style="text-align: right">
      <!-- <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >取 消</el-button
      > -->
      <el-button
        v-if="isEmprty(this.currentId)"
        type="primary"
        :loading="submitting"
        @click="submitForm('userForm')"
        >{{ submitting ? "保存中..." : "保存" }}</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('userForm')"
        v-if="!isEmprty(this.currentId)"
        >{{ submitting ? "保存中..." : "保存修改" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PostUsers, PutUserById, GetUserById, DuplicateUserName } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { throttle } from "@/utils/common";
import { onRefreshCurrentPage } from "@/utils/auth";
import formSelection from "../mixins/formSelection";
import RolesForm from "./RolesForm";
export default {
  components: {
    RolesForm,
  },
  props: {
    uid: {
      type: [Number, String],
      default: "",
    },
  },
  mixins: [formSelection],
  data() {
    return {
      loading: false,
      submitting: false,
      isUsernameExist: false,
      currentId: null,
      form: {
        name: "",
        roleIds: [],
        username: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名称", trigger: "blur" }],
        username: [{ required: true, validator: this.onCheckUsername }],

        roleIds: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
      rolesOptions: [],
      departmentOptions: [],
    };
  },
  watch: {
    uid: {
      handler(value) {
        if (!isEmprty(this.uid)) {
          this.currentId = value;
          this.getFormData();
        } else {
          this.currentId = null;
          this.inintForm();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initFormOptions();
  },
  methods: {
    isEmprty,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && !this.isUsernameExist) {
          this.submitting = true;
          PostUsers([this.form])
            .then((res) => {
              this.submitting = false;
              this.showAddResult("账号创建成功", res.data[0].initPassword).then(
                () => {
                  this.onEmitSuccess(res.data[0]);
                  this.inintForm();
                }
              );
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("新增失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          // delete this.form.username;
          PutUserById([
            {
              id: this.currentId,
              ...this.form,
            },
          ])
            .then((res) => {
              this.submitting = false;
              this.inintForm();
              this.$message.success("修改成功");
              this.onEmitSuccess(res.data[0]);
              this.$store.commit("auth/SET_ROLES", []); //清空路由，然后重新新加载页面
              onRefreshCurrentPage(this.$route.fullPath);
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData() {
      this.loading = true;
      GetUserById(this.currentId)
        .then((res) => {
          let { name, roles, username } = res.data;

          this.form.name = name;
          this.form.roleIds = roles.map((item) => item.id);
          this.form.username = username;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onCheckUsername(rule, value, callback) {
      // console.log('rule', rule)
      if (!value || value === "") {
        return callback(new Error("请输入登录账户名"));
      } else {
        // 只在新增模式下检测账号是否重复
        if (!isEmprty(this.currentId)) {
          callback();
          return false;
        }
        throttle(() => {
          DuplicateUserName(value)
            .then((res) => {
              this.isUsernameExist = res.data;
              if (res.data) {
                callback(new Error("登录账户名已经存在"));
              } else {
                callback();
              }
            })
            .catch((err) => {
              callback(new Error("登录账户名查重异常，请稍后重试"));
              ShowApiError("用户登录名查重请求失败", err);
            });
        }, 800)();
      }
    },
    onCancel(data = {}) {
      this.$emit("cancel", data);
    },
    onEmitSuccess(data = {}) {
      this.$emit("success", data);
    },
    inintForm() {
      this.$nextTick(() => {
        this.$refs["userForm"].resetFields();
      });
    },
    showAddResult(title = "账号创建成功", initPassword = "") {
      return new Promise((resolve, reject) => {
        const h = this.$createElement;
        let content = h("div", null, [
          h("p", { style: "color: red" }, "请妥善保存密码！"),
          h("p", null, `初始密码：${initPassword}`),
        ]);
        this.$alert(content, title, {
          confirmButtonText: "确定",
          callback: () => {
            resolve();
            reject();
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.company-ruleForm {
  .base-form-item {
    width: 600px;
    height: 40px;
    overflow: hidden;
    ::v-deep {
      .el-form-item__content {
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .role-form-item {
    ::v-deep {
      .component-wrap {
        padding: 0;
      }
    }
  }
}
</style>
